<app-m3-wrapper>
  <!-- TODO: auto_awesome does not exist in material symbols,
        find replacement before removing material icons -->
  <!-- note: one of the stupidest things about angular coming up.
       only difference is "mat-button" and "mat-icon-button" -->
  @if (triggerLabel) {
    <button #trigger="cdkOverlayOrigin"
            [style.bottom]="bottom"
            [style.left]="left"
            [style.right]="right"
            [style.top]="top"
            [style.position]="position"
            [class.open]="panelOpen"
            cdkOverlayOrigin
            class="trigger-button"
            mat-button
            (click)="panelOpen = !panelOpen"
    >
      <mat-icon class="trigger-icon" [class.open]="panelOpen">auto_awesome</mat-icon>
      <span class="trigger-label">{{ triggerLabel }}</span>
    </button>

    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="panelOpen"
      [cdkConnectedOverlayHasBackdrop]="panelOpen"
      [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop-cs'"
      (backdropClick)="panelOpen = false"
      (close)="panelOpen = false"
    >
      <ng-container *ngTemplateOutlet="modalContent"></ng-container>
    </ng-template>
  } @else {
    <button #trigger="cdkOverlayOrigin"
            [style.bottom]="bottom"
            [style.left]="left"
            [style.right]="right"
            [style.top]="top"
            [style.position]="position"
            [class.open]="panelOpen"
            cdkOverlayOrigin
            class="trigger-button"
            mat-icon-button
            (click)="panelOpen = !panelOpen"
    >
      <mat-icon class="trigger-icon" [class.open]="panelOpen">auto_awesome</mat-icon>
    </button>

    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="panelOpen"
      [cdkConnectedOverlayHasBackdrop]="panelOpen"
      [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop-cs'"
      (backdropClick)="panelOpen = false"
    >
      <ng-container *ngTemplateOutlet="modalContent"></ng-container>
    </ng-template>
  }
</app-m3-wrapper>


<ng-template #modalContent>
  <app-m3-wrapper>
    <mat-card>
      <mat-card-content>
        <ng-content></ng-content>
      </mat-card-content>
      <mat-card-footer>
        <mat-slide-toggle
          [checked]="featureEnabled"
          (toggleChange)="onToggle()">{{ featureLabel }}</mat-slide-toggle>
      </mat-card-footer>
    </mat-card>
  </app-m3-wrapper>
</ng-template>
