<div class="search-result-view" id="searchResultViews"
     [ngClass]="searchContainer.searchResultViewName + '-view'">
  <div *ngIf="searchContainer.searching" class="search-result-view__spinner-container">
    <div class="search-result-view__spinner-content">
      <mat-spinner color="accent" diameter="30"></mat-spinner>
      <p translate="TRANS__SEARCH__LOADING_INFO"></p>
    </div>
  </div>

  <div *ngIf="searchContainer && !searchContainer.searching && searchContainer.searchResult" class="h-full">

    <div *ngIf="searchContainer.searchResult.search_count === 0">
      <h1 translate="TRANS__SEARCH_RESULT__NO_SEARCH_HITS"></h1>
      <h5 [translate]="noSearchResultText"></h5>
    </div>

    <div *ngIf="searchContainer.searchResult.search_count > 0" class="h-full">

      <div *ngIf="searchContainer.scrollInfo.searchingScroll && searchContainer.scrollInfo.searchingScrollDir === 'up'"
           class="searching-scroll-loader searching-scroll-loader__top">
        <mat-spinner color="accent" diameter="30"></mat-spinner>
        <p class="searching-scroll-loader__info" translate="TRANS__SEARCH__LOADING_INFO"></p>
      </div>

      <ng-container [ngSwitch]="searchContainer.searchResultViewName">
        <app-search-result-view-thumbnail
          class="h-full block"
          *ngSwitchCase="SearchResultViewNames.THUMBNAIL"
          [width]="containerWidth"
          [searchContainer]="searchContainer">
        </app-search-result-view-thumbnail>
        <app-search-result-view-gallery
          class="h-full block"
          *ngSwitchCase="SearchResultViewNames.GALLERY"
          [width]="containerWidth"
          [searchContainer]="searchContainer">
        </app-search-result-view-gallery>
        <app-search-result-view-list-thumbnail
          class="h-full block"
          *ngSwitchCase="SearchResultViewNames.LIST_THUMBNAIL"
          [width]="containerWidth"
          [searchContainer]="searchContainer">
        </app-search-result-view-list-thumbnail>
        <app-search-result-view-list
          *ngSwitchCase="SearchResultViewNames.LIST"
          class="search-result-view__list h-full block"
          [searchContainer]="searchContainer">
        </app-search-result-view-list>
        <app-search-result-view-list-selector
          class="h-full block"
          *ngSwitchCase="SearchResultViewNames.SELECTOR"
          [searchContainer]="searchContainer">
        </app-search-result-view-list-selector>
      </ng-container>

      <div *ngIf="searchContainer.scrollInfo.searchingScroll && searchContainer.scrollInfo.searchingScrollDir === 'down'"
           class="searching-scroll-loader">
        <mat-spinner color="accent" diameter="30"></mat-spinner>
        <p class="searching-scroll-loader__info" translate="TRANS__SEARCH__LOADING_INFO"></p>
      </div>
    </div>
  </div>
</div>
