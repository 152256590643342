import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {CommonModule} from '@angular/common';
import {CdkTrapFocus} from '@angular/cdk/a11y';
import {M3WrapperComponent} from '../../../../m3-wrapper/m3-wrapper.component';

export interface ActionMenuItem {
  id: string;
  type: 'action' | 'group';
  label: string;
  icon?: string;
  children?: ActionMenuItem[];
  [key: string]: any;
}

/**
 * Menu-group-button - a button, with optional grouping.
 * Items can be grouped two levels for a drop-down menu with more options.
 * not a recursive component because of https://github.com/angular/components/issues/26243 (no accessibility)
 */
@Component({
  selector: 'app-menu-group-button',
  standalone: true,
  imports: [
    CommonModule,
    MatButton,
    MatIcon,
    TranslateModule,
    MatMenuItem,
    MatMenu,
    MatMenuTrigger,
    CdkTrapFocus,
    M3WrapperComponent,
  ],
  templateUrl: './menu-group-button.component.html',
  styleUrl: './menu-group-button.component.scss'
})
export class MenuGroupButtonComponent {

  @Input() item: ActionMenuItem;
  @Output() itemClick = new EventEmitter<ActionMenuItem>()

}
