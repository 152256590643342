import {ChangeDetectorRef, Component, OnDestroy, OnInit, signal} from "@angular/core";
import {CmsApiService} from "../../../../core/cms-api.service";
import {ModelStore} from "../../../../core/ModelStore/ModelStore";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {FieldSection, ReportUserGenerated} from "../../../../core/ModelStore/models/ReportUserGenerated";
import {DragulaService} from "ng2-dragula";
import {ReportViewType} from "../../../../core/ModelStore/models/ReportViewType";
import {ReportUserTemplate} from "../../../../core/ModelStore/models/ReportUserTemplate";
import {MatSelectChange} from "@angular/material/select";
import {saveAs} from "file-saver";
import {OperationStepExecutionParams} from "../../../../core/definitions/operation-step-execution-params";
import {OperationAndSectionsContainerParams, OperationService} from "../../../../operations/operation.service";
import {OperationTarget} from "../../../../core/definitions/operation-target.enum";
import {OperationDef} from "../../../../core/definitions/operation-def";
import {OperationModel} from "../../../../core/ModelStore/models/Operation";
import {OperationStep} from "../../../../core/definitions/operation-step";

@Component({
  selector: 'edit-report',
  templateUrl: './edit-report.component.html',
  styleUrls: ['./edit-report.component.scss']
})
export class EditReportComponent implements OnInit, OnDestroy {
  reportId: string;
  generatedReport: ReportUserGenerated;
  reportUserTemplates: ReportUserTemplate[];
  selectedReportUserTemplateId: string;
  reportViewTypes: ReportViewType[];
  selectedReportViewType: ReportViewType;
  showCreateNewReportSettingsArea: boolean = false;
  newReportSettings: ReportUserTemplate = null;
  showDeleteReportSettingsArea: boolean = false;
  showViewOptions = true;
  dragulaId: string = 'HANDLES';
  operation : OperationModel = null
  subs = new Subscription();
  reportGenerationInterval: any = null;

  downloadPdfStarted = signal(false);
  reportGenerationInProgress = signal(false);

  viewOptionsCheckboxValues = [
    {key: 'vis_ledetekster', label: 'TRANS__REPORTS_V2_SELECTIONS_SHOW_LABELS', selected: false},
    {key: 'med_helbilde', label: 'TRANS__REPORTS_V2_SELECTIONS_FULL_IMAGE', selected: false},
    {key: 'høyrejuster_bilder', label: 'TRANS__REPORTS_V2_SELECTIONS_RIGHT_ADJUSTED_IMAGE', selected: false},
    {key: 'ledetekst_og_verdi_på_en_linje', label: 'TRANS__REPORTS_V2_SELECTIONS_LABELS_SAME_LINE', selected: false},
    {key: 'med_frimerkebilder', label: 'TRANS__REPORTS_V2_SELECTIONS_STAMP_IMAGES', selected: false},
    {key: 'vise_fotograf_for_bilder', label: 'TRANS__REPORTS_V2_SELECTIONS_SHOW_PHOTOGRAPHER', selected: false},
    {key: 'vis_plasseringsdetaljer', label: 'TRANS__REPORTS_V2_SELECTIONS_SHOW_PLACEMENT', selected: false},
    {key: 'vis_søkekriterium', label: 'TRANS__REPORTS_V2_SELECTIONS_SHOW_SEARCH', selected: false},
    {key: 'vis_sidetall', label: 'TRANS__REPORTS_V2_SELECTIONS_SHOW_PAGENUM', selected: false}
  ]

  private paramMapSubscription: Subscription;

  constructor(private readonly cms: CmsApiService,
              private modelStore: ModelStore,
              private route: ActivatedRoute,
              private cdRef:ChangeDetectorRef,
              private operationService: OperationService,
              private dragulaService: DragulaService) {
    dragulaService.createGroup(this.dragulaId, {
      invalid: (el) => el.classList.contains('report-collapsible-list-child-item')
    });

    this.subs.add(dragulaService.dropModel(this.dragulaId)
      .subscribe(({ name, el, target, source, sourceModel, targetModel, item }) => {
        const targetPosition = targetModel.findIndex((field: any) => field.name === item.name);
        const sourcePosition = this.generatedReport.fieldSections.findIndex((field: any) => field.name === item.name);

        //When item is dropped, move the source item to the target position in this.availableFieldsKeyList
        this.generatedReport.fieldSections.splice(targetPosition, 0, this.generatedReport.fieldSections.splice(sourcePosition, 1)[0]);

        console.log(this.generatedReport);

        this.triggerUIUpdate();
      }));
  }

  ngOnDestroy() {
    this.dragulaService.destroy(this.dragulaId);
    this.subs.unsubscribe();
    this.paramMapSubscription.unsubscribe();

    if (this.reportGenerationInterval) {
      clearInterval(this.reportGenerationInterval);
      this.reportGenerationInterval = null;
    }
  }

  async ngOnInit(): Promise<void> {
    //let operationStatus = this.modelStore.findModel('operation_status', '7404351c-49f0-474b-89f3-5cf1bdbc3016');
    //await operationStatus.loading;

    this.operation = this.modelStore.findModel('operation', 'reports_v2');
    await this.operation.loading;
    console.log('------OPERATION REPORTS_V2:');
    console.log(this.operation);

    this.reportUserTemplates = this.modelStore.findAllModels('report_user_template');
    // @ts-ignore
    await this.reportUserTemplates.loading;

    this.reportViewTypes = this.modelStore.findAllModels('report_view_type');
    // @ts-ignore
    await this.reportViewTypes.loading;

    this.paramMapSubscription = this.route.paramMap.subscribe(params => {
      this.reportId = params.get('report_id');
    });

    if (this.reportId)  {
      this.generatedReport = this.modelStore.findModel('report_user_generated', this.reportId);
      await this.generatedReport.loading;

      if (this.generatedReport.reportStatus === 'generating' || this.generatedReport.reportStatus === 'generated') {
        this.pollForFinishedPdf(this.reportId);
      }
      if (this.generatedReport.reportViewTypeId) {
        this.selectedReportViewType = this.reportViewTypes.find(viewType => viewType.id === this.generatedReport.reportViewTypeId);
      }

      if (this.generatedReport.reportViewOptions) {
        this.viewOptionsCheckboxValues.forEach(option => {
          option.selected = this.generatedReport.reportViewOptions.split(',').includes(option.key);
        });
      }
    }

    await this.fetchFieldSections();
  }

  private async fetchFieldSections() {
    let objectTypes = this.generatedReport.getObjectTypes();
    let fieldSections: FieldSection[] = [];
    if (objectTypes.length > 0) {
      //For each object type fetch fieldSearchResult and join the array returned via the name property
      for (const objectType of objectTypes) {
        let fieldSearchResult = await this.cms.getModelSections({object_type: objectType});
        fieldSearchResult.forEach(section => {
          //If fieldSearchResult does not contain a section with the same name-property, push it to the fieldSections array
          if (!fieldSections.find(fieldSection => fieldSection['name'] === section['name'])) {
            // @ts-ignore
            fieldSections.push(section);
          }
        });
      }

      this.generatedReport.applyFieldSectionsFromBackend(fieldSections);
      this.triggerUIUpdate();
    }
  }

  doShowCreateNewReportSettingsArea() {
    this.newReportSettings = new ReportUserTemplate();
    this.showCreateNewReportSettingsArea = true;
  }

  async doPersistNewReportSettings() {
    if (this.newReportSettings != null && this.newReportSettings.name != null && this.generatedReport != null) {
      this.newReportSettings.reportViewTypeId = this.generatedReport.reportViewTypeId;
      this.newReportSettings.reportViewOptions = this.generatedReport.reportViewOptions;
      this.newReportSettings.fieldSections = this.generatedReport.fieldSections;

      let modelCreated = this.modelStore.createModel({modelSingularKey: 'report_user_template', record: this.newReportSettings});
      await modelCreated.loading;

      this.newReportSettings = null;
      this.showCreateNewReportSettingsArea = false;
      this.reportUserTemplates = this.modelStore.findAllModels('report_user_template');
      await this.reportUserTemplates;

      //select the newly created user template
      this.selectedReportUserTemplateId = modelCreated.id;
      this.triggerUIUpdate();
    }
  }

  async persistSelectedUserTemplate() {
    if (this.selectedReportUserTemplateId) {
      this.reportUserTemplates.forEach(template => {
        if (template.id === this.selectedReportUserTemplateId) {
          template.reportViewTypeId = this.generatedReport.reportViewTypeId;
          template.reportViewOptions = this.generatedReport.reportViewOptions;
          template.fieldSections = this.generatedReport.fieldSections;
          this.modelStore.updateModel({modelSingularKey: 'report_user_template', record: template});
        }
      });
    }
  }

  doCancelPersistingNewReportSettings() {
    this.newReportSettings = null;
    this.showCreateNewReportSettingsArea = false;
  }

  doShowDeleteReportSettingsArea() {
    this.showDeleteReportSettingsArea = true;
  }

  async doDeleteReportSettings() {
    if (this.selectedReportUserTemplateId) {
      this.reportUserTemplates.forEach(async template => {
        if (template.id === this.selectedReportUserTemplateId) {
          let deleteStatus = this.modelStore.deleteModel('report_user_template', template.id);
          await deleteStatus.loading;
          this.selectedReportUserTemplateId = null;
          this.reportUserTemplates = this.modelStore.findAllModels('report_user_template');
        }
      });
    }
    this.showDeleteReportSettingsArea = false
  }

  onUserTemplateSelected(event: MatSelectChange) {
    console.log('Selected template ID:', event.value);
    this.reportUserTemplates.forEach(template => {
      if (template.id === event.value) {
        //Set reportUserTemplate ID to the selected value in the selectbox
        this.selectedReportUserTemplateId = template.id;

        //Set the templates report View type to the selected report
        this.selectedReportViewType = this.reportViewTypes.find(viewType => viewType.id === template.reportViewTypeId);

        //Toggle the correct view options (checkboxes)
        this.viewOptionsCheckboxValues.forEach(option => {
          option.selected = template.reportViewOptions.split(',').includes(option.key);
        })
        this.generatedReport.reportViewOptions = this.viewOptionsCheckboxValues.filter(option =>
          option.selected).map(option =>
          option.key).join(',');

        this.reportViewTypeChanged();

        //Set the fieldSections to the selected template fieldSections
        this.generatedReport.fieldSections = template.fieldSections;

        //Trigger UI update
        this.triggerUIUpdate();
      }
    });
  }

  async persistUserReport() {
    let newReportTemplate = this.modelStore.updateModel({modelSingularKey: 'report_user_generated', record: this.generatedReport});
    this.generatedReport = null;
    await newReportTemplate.loading;
    this.generatedReport = this.modelStore.findModel('report_user_generated', newReportTemplate.id);
  }

  async requestReportPdf(reportId) {
    this.reportGenerationInProgress.set(true);
    let newReportTemplate = this.modelStore.updateModel({modelSingularKey: 'report_user_generated', record: this.generatedReport});
    await newReportTemplate.loading;
    this.generatedReport = this.modelStore.findModel('report_user_generated', newReportTemplate.id);

    const localStorageItem = localStorage.getItem('primus.client.main');

    let params = {
        localStorageItem: null,
        reportId: reportId,
        accessToken: localStorage.getItem('kit.access_token'),
        idToken: localStorage.getItem('kit.id_token')
    }
    let parsedItem: any = {};

    if (localStorageItem) {
      try {
        parsedItem = JSON.parse(localStorageItem);
      } catch (e) {
        console.error('Error parsing localStorageItem:', e);
      }
    }

    params.localStorageItem = {
      name: parsedItem.name || '',
      shortName: parsedItem.shortName || '',
      api: parsedItem.api || '',
      id: parsedItem.id || '',
      online: parsedItem.online || false,
    }

    let operationStepParams = new OperationStepExecutionParams();
    operationStepParams.operation_objects = [params];
    operationStepParams.operation_type_id = "6f6f6c0b-6a5d-4b6b-9e3a-3e1b8f5f5f5f";
    operationStepParams.operation_step_index = 0;

    /*await this.cms.executeOperationStep(operationStepParams);
    await this.pollForFinishedPdf(reportId);
    */

    console.log('getting operation container');
    let operationContainer = await this.operationService.createOperationAndRootSectionsContainer({
      operationTarget: OperationTarget.CREATE_REPORT_PDF,
      executorType: 'reports_v2',
      operationStepType: 'generate_report_pdf'
    } as OperationAndSectionsContainerParams);

    console.log('OperationContainer: ', operationContainer);

    // TODO: Use operationService:
    /*let operationContainer = await this.operationService.createOperationContainer(OperationTarget.CREATE_REPORT_PDF);
    console.log(operationContainer);
    let operationDef = new OperationDef();
    operationDef.operation_type_id = this.operation.operationTypeId;
    operationDef.executor_type = this.operation.executorType;
    operationDef.operation_type = this.operation.operationType;

    let operationStep = new OperationStep();


    operationDef.operation_steps;*/

    /*
    "operations": [
        {
            "executor_type": "reports_v2",
            "operation_type_id": "6f6f6c0b-6a5d-4b6b-9e3a-3e1b8f5f5f5f",
            "operation_type": "operation",
            "read_access": true,
            "menu_type": "",
            "menu_title": "",
            "meny_icon": "",
            "restrictions": {},
            "operation_steps": [
                {
                    "operation_step_type": "generate_report_pdf",
                    "operation_model": "ReportSettingsOperationModel",
                    "object_actions": []
                },
                {
                    "operation_step_type": "generated_report_pdf",
                    "operation_model": "ReportSettingsOperationModel",
                    "object_actions": []
                }
            ]
        }
    ]
     */
    //await this.operationService.setOperations(operationContainer);
  }

  private pollForFinishedPdf(reportId) {
    let self = this;
    this.reportGenerationInterval = setInterval(async () => {
      let tempReport = this.modelStore.reloadModel('report_user_generated', reportId);
      await tempReport.loading;
      if (tempReport.reportStatus === 'generated') {
        this.reportGenerationInProgress.set(false);
        self.generatedReport = tempReport;

        await this.fetchFieldSections();
        this.triggerUIUpdate();
        clearInterval(this.reportGenerationInterval);
      }
    }, 15000);
  }

  async downloadSinglePdf() {
    this.downloadPdfStarted.set(true);
    let downloadUrl = await this.cms.requestReportPdfFromDms(this.generatedReport.id);
    if (downloadUrl.url) {
      let pdfFile = await fetch(downloadUrl.url);
      let pdfFileBlob = await pdfFile.blob();
      saveAs(pdfFileBlob, this.generatedReport.id + '.pdf');
    }
    this.downloadPdfStarted .set(false);
  }

  getRefreshToken() {
    const name = 'refresh_token';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      const c = ca[i].trim();
      if (c.indexOf(name) === 0) {
        return c.substring(name.length + 1, c.length);
      }
    }
    return '';
  }

  doShowViewOptions() {
    this.showViewOptions = true;
    this.triggerUIUpdate();
  }

  doHideViewOptions() {
    this.showViewOptions = false;
    this.triggerUIUpdate();
  }

  toggleFieldExpanded(field: any) {
    field.isExpanded = !field.isExpanded;
  }

  toggleTopLevelField(section: any) {
    section.fields.forEach((field: any) => {
      field.isSelected = section.isSelected;
    });

    this.triggerUIUpdate();
  }

  private triggerUIUpdate() {
    // @ts-ignore
    this.generatedReport = {...this.generatedReport};

    // Manually trigger change detection
    this.cdRef.detectChanges();
  }

  toggleSubField(section: any) {
    if (!section.isSelected) {
      section.isSelected = true;
    }

    if (section.fields.every((field: any) => !field.isSelected)) {
      section.isSelected = false;
    }

    this.triggerUIUpdate();
  }

  markAllTopLevelFields(checked: boolean) {
    this.generatedReport.fieldSections.forEach((value) => {
      // @ts-ignore
      value.isSelected = checked;
      // @ts-ignore
      value.fields.forEach((field: any) => {
        field.isSelected = checked;
      });
    });

    this.triggerUIUpdate();
  }

  reportViewTypeChanged() {
    this.generatedReport.reportViewTypeId = this.selectedReportViewType.id;
    this.viewOptionsCheckboxValues.forEach(option => {
      if (option.selected && !this.selectedReportViewType.viewChoices.includes(option.key)) {
        option.selected = false;
      }
    });

    this.generatedReport.reportViewOptions = this.viewOptionsCheckboxValues.filter(option =>
      option.selected).map(option =>
      option.key).join(',');

    this.triggerUIUpdate();
  }

  toggleViewChoice(viewChoice: any) {
    viewChoice.selected = !viewChoice.selected
    //find all viewChoices that are selected in a comma separated string
    this.generatedReport.reportViewOptions = this.viewOptionsCheckboxValues.filter(option =>
      option.selected).map(option =>
      option.key).join(',');

    this.triggerUIUpdate();
  }

  createTree(objects: any[]) {
    // Sort the objects alphabetically based on the artifact_name property
    objects.sort((a, b) => a.artifact_name.localeCompare(b.artifact_name));

    // Create a map to store the hierarchy
    const hierarchy = new Map();

    objects.forEach(obj => {
      // Split the name property to get the hierarchy levels
      const levels = obj.name.split(' / ');
      // Start at the top level of the hierarchy
      let currentLevel = hierarchy;

      levels.forEach((level, i) => {
        // If the current level doesn't exist yet, create it
        if (!currentLevel.has(level)) {
          if (i === levels.length - 1) {
            // If this is the last level, add the complete JSON structure
            currentLevel.set(level, { ...obj, children: new Map() });
          } else {
            // Otherwise, create a new object with a children property
            // and keep the original name
            currentLevel.set(level, { name: obj.name, isSelected: false, isExpanded: false, children: new Map() });
          }
        }

        // Move to the next level
        currentLevel = currentLevel.get(level).children;
      });
    });

    //Iterate over keys and delete all keys that have no children
    hierarchy.forEach((value, key) => {
      if (value.children.size === 0) {
        hierarchy.delete(key);
      }
    });

    return hierarchy;
  }

  updateReportPreview() {
    console.log('UPDATING REPORT PREVIEW');
    this.cdRef.detectChanges();
  }
}
