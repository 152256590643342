import { Injectable } from '@angular/core';
import {CmsApiService} from "../core/cms-api.service";
import {SortOrderMenu} from "../core/definitions/search-objects";

@Injectable({
  providedIn: 'root'
})
export class SearchViewSortOrderMenuService {
  private sortOrderMenus: {[name: string]: SortOrderMenu[]} = {};

  constructor(private cms: CmsApiService) {
    this.cms.getSearchViewSortOrderMenus().then(res => {
      this.sortOrderMenus = res;
    })
  }

  getSortOrderMenus(name: string): SortOrderMenu[] {
    return this.sortOrderMenus[name] || [];
  }
}
