import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, Renderer2} from '@angular/core';
import {UiToolsService} from '../../core/ui-tools.service';
import {AConst} from '../../core/a-const.enum';
import {TranslateService} from '@ngx-translate/core';
import {QueryMenu} from '../../core/definitions/search-objects';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {CmsApiService} from '../../core/cms-api.service';
import {SearchExecutorService} from '../search-executor.service';
import {
  SearchCategoryComponent
} from '../advanced-search-and-search-category/search-category/search-category.component';
import {SearchCategory} from '../../core/definitions/advanced-search/search-category';
import {SearchContainer} from '../../core/definitions/search-container';
import {QueryContainer} from '../../core/query-container';
import {SearchFilterService} from '../search-filter.service';
import {PrimusRouterService} from '../../core/primus-router.service';
import {PrimusStateMapperService} from '../../core/primus-state-mapper.service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent, ConfirmDialogData} from '../../object-edit/confirm-dialog/confirm-dialog.component';
import {take} from 'rxjs/operators';
import {HierarchicFilterGroupService} from "../hierarchic-filter-group.service";
import {QueryFieldMenuService} from "../query-field-menu.service";

@Component({
  selector: 'app-search-query',
  templateUrl: './search-query.component.html',
  styleUrls: ['./search-query.component.scss']
})
export class SearchQueryComponent implements OnChanges, OnDestroy {

  @Input() searchContainer: SearchContainer;
  @Input() placeholder: string;
  @Input() hideIcon: boolean;
  @Input() setFocus;
  @Input() newLayout;
  @Input() noBorder;
  @Input() useQueryChange: boolean;

  @Output() queryChange = new EventEmitter<string>();

  queryContainer: QueryContainer;
  private stopSizeWatch;
  private menuPathPlaceholder;
  showPathMenu: boolean;
  showMenu = false;
  // startScannerCamera = false;
  selectedPathViewMenu: string;
  mediumScreen: boolean;
  pathAsFilter: boolean;
  queryTimeoutId;
  // barcodeFormat = BarcodeFormat;

  storedSearchCategoriesForUser: Array<SearchCategory> = [];

  constructor(private readonly bottomSheet: MatBottomSheet,
              private primusRouter: PrimusRouterService,
              private searchExecutorService: SearchExecutorService,
              private primusStateMapper: PrimusStateMapperService,
              private uiTools: UiToolsService,
              private renderer: Renderer2,
              private modalService: MatDialog,
              private translate: TranslateService,
              private cmsApi: CmsApiService,
              private searchFilterService: SearchFilterService,
              private hierarchicFilterGroupService: HierarchicFilterGroupService,
              private queryFieldMenuService: QueryFieldMenuService) {
    this.loadSearchCategories().then();
  }

  ngOnChanges() {
    this.queryContainer = this.searchContainer.queryContainer;
    this.stopSizeWatch = this.uiTools.addWindowSizeListener(
      (newVal) => {
        if (newVal) {
          this.mediumScreen = newVal.width < 1025;
        }
      });
    this.mediumScreen = this.uiTools.setMediumScreenSizeOnLoad(this.mediumScreen);
    if (this.setFocus && !this.mediumScreen) {
      setTimeout(() => {
        const idName = this.newLayout ? '#searchQueryNew' : '#searchQueryOld';
        const element = this.renderer.selectRootElement(idName);
        element.focus();
      }, 1000);
    }

    this.pathAsFilter = this.primusStateMapper.isObjectState(this.primusRouter.currentState());

    if (this.placeholder) {
      this.setCurrentQueryPlaceholder(this.placeholder);
    } else if (this.queryContainer.currentQueryPlaceholder === undefined && this.queryContainer.queryPlaceholder) {
      this.setCurrentQueryPlaceholder(this.queryContainer.queryPlaceholder);
    } else if (!this.searchContainer.currentPathView.search_view.query_menus) {
      this.setCurrentQueryPlaceholder('TRANS__QUERY_MENU__GENERAL_SEARCH');
    }
  }

  get queryPlaceholder() {
    if (this.menuPathPlaceholder !== undefined) {
      return this.menuPathPlaceholder;
    } else if (this.queryContainer.currentQueryPlaceholder !== undefined) {
      return this.queryContainer.currentQueryPlaceholder;
    }
  }

  ngOnDestroy() {
    if (this.stopSizeWatch) {
      this.uiTools.removeWindowSizeListener(this.stopSizeWatch);
    }
  }

  queryChanged() {
    if (this.queryTimeoutId) {
      clearTimeout(this.queryTimeoutId);
      this.queryTimeoutId = undefined;
    }
    this.queryTimeoutId = setTimeout(() => {
      const targetState = this.searchContainer.state.targetState;
      this.searchExecutorService.resetSearchPosition(this.searchContainer);
      // Searching while typing shall only occur if search will not cause
      // change of target state
      if (!targetState || targetState === this.primusRouter.currentState()) {
        this.searchContainer.filtersFacets.hierarchicFilterGroup = null;
        this.hierarchicFilterGroupService.setHierarchicFilterGroup(this.searchContainer).then(() => {
          this.search().then();
        })
      }
    }, 500);
  }

  submit() {
    if (this.queryContainer.selectedQueryMenu.query_type !== 'advanced') {
      this.search().then();
    }
  }

  toggleMenu() {
    // this.startScannerCamera = false;
    const queryMenus = this.searchContainer.currentPathView.search_view.query_menus;
    if (queryMenus) {
      this.showMenu = !this.showMenu;
    } else {
      this.search().then();
    }
  }

  togglePathViewMenus(menu) {
    if (menu.query_type !== this.selectedPathViewMenu) {
      this.selectedPathViewMenu = menu.query_type;
    } else {
      this.selectedPathViewMenu = null;
    }
  }

  selectQueryMenu(menu: QueryMenu) {
    // this.startScannerCamera = false;
    // if (menu.query_type === 'advanced') {
    //   this.bottomSheet.open(AdvancedSearchComponent);
    //   this.queryContainer.selectedQueryMenu.selected = false;
    //   this.queryContainer.selectedQueryMenu = menu;
    //   menu.selected = true;
    //   this.queryContainer.queryField = null;
    //   this.queryContainer.queryPlaceholder = menu.description;
    //   this.setCurrentQueryPlaceholder(this.queryContainer.queryPlaceholder);
    // }

    if (menu.query_type === 'search_category') {
      this.editOrCreateSearchCategory();
    }

    if (!menu.selected) {
      let runSearch = true;
      switch (menu.query_type) {
        case 'general':
          this.queryContainer.selectedQueryMenu.selected = false;
          this.queryContainer.selectedQueryMenu = menu;
          menu.selected = true;
          this.queryContainer.queryField = null;
          this.queryContainer.queryPlaceholder = this.queryContainer.selectedQueryMenu.description;
          this.setCurrentQueryPlaceholder(this.queryContainer.queryPlaceholder);
          break;
        // case 'scanner':
        //   this.startScannerCamera = true;
        //   $event.stopPropagation();
        //   break;
        case 'advanced':
          runSearch = false;
          this.queryContainer.selectedQueryMenu.selected = false;
          this.queryContainer.selectedQueryMenu = menu;
          menu.selected = true;
          this.queryContainer.queryField = null;
          this.queryContainer.queryPlaceholder = menu.description;
          this.setCurrentQueryPlaceholder(this.queryContainer.queryPlaceholder);
          break;
        case 'search_category':
          return;
        default:
          this.queryContainer.selectedQueryMenu.selected = false;
          this.queryContainer.selectedQueryMenu = menu;
          menu.selected = true;
          this.setCurrentQueryPlaceholder(menu.description);
          this.queryContainer.queryField = menu[AConst.QUERY_FIELD];
      }
      if (menu.query_type !== 'search_category' && this.searchContainer.searchCategory) {
        this.useSearchCategory(null);
      }
      if (runSearch) this.search().then();
    }
  }

  clearSearch() {
    this.queryContainer.query = null;
    this.queryChanged();
  }

  setScanned(scanCode: string) {
    this.queryContainer.query = scanCode;
    this.queryChanged();
  }

  get pathViewMenus(): QueryMenu {
    return this.queryFieldMenuService.getQueryMenusForCurrentPathView(this.searchContainer);
  }

  get searchCount() {
    let res = 0;
    if (this.searchContainer.searchResult) {
      res = this.searchContainer.searchResult.search_count;
    }
    return res;
  }

  onMenuPathSet(menuTitle) {
    this.menuPathPlaceholder = this.createPlaceHolder(menuTitle);
  }

  private setCurrentQueryPlaceholder(placeholder: string, translate: boolean = true) {
    this.queryContainer.currentQueryPlaceholder = translate ? this.translate.instant(placeholder) : placeholder;
  }

  private createPlaceHolder(text) {
    const searchIn = this.translate.instant('TRANS__FILTER__SEARCH_IN');
    const textTrans = this.translate.instant(text);
    return searchIn + ' ' + textTrans.toLocaleLowerCase();
  }

  private async search() {
    if (this.useQueryChange) {
      this.queryChange.emit(this.queryContainer.query);
    } else if (!!this.searchContainer) {
      if (this.searchContainer.state.targetState && this.primusRouter.currentState() !== this.searchContainer.state.targetState) {
        await this.primusRouter.navigateState(
          this.searchContainer.state.targetState,
          {query: this.queryContainer.query, path: this.searchContainer.path},
          {reload: true});
      } else {
        this.searchContainer.selections.allSelected = false;
        await this.searchExecutorService.runSearch(this.searchContainer);

        if (this.queryContainer.query === '*' || !this.queryContainer.query) {
          // This will reset search filters so that missing filters will be added, useful for hierarchic list search or
          // when removing query text
          await this.searchFilterService.setCheckFilterMenusAfterSearch(this.searchContainer);
          // await this.searchFilterService.setVisibleFiltersForAllFilterGroups(this.searchContainer);
        }
      }
    }
  }

  public editOrCreateSearchCategory(search?: SearchCategory): void {
    this.bottomSheet
      .open(SearchCategoryComponent, {data: !!search ? search : null})
      .afterDismissed()
      .subscribe((dataFromBottomSheet) => {
        this.loadSearchCategories(dataFromBottomSheet).then();
      });
  }

  public useSearchCategory(search?: SearchCategory, clearMenuSelection: boolean = false): void {
    this.searchContainer.searchCategory = !!search ? search : undefined;
    if (clearMenuSelection) {
      this.queryContainer.selectedQueryMenu.selected = false;
    }
    if (this.searchContainer.searchCategory) {
      this.setCurrentCategoryQuery(this.searchContainer.searchCategory.category.selectedFields);
    }
    this.search().then();
  }

  private setCurrentCategoryQuery(selectedFields) {
    this.setCurrentQueryPlaceholder('TRANS__SEARCH_QUERY__SEARCH_CATEGORY__SEARCH_IN_FIELDS');

    selectedFields.forEach((fields, index) => {
      if (index === selectedFields.length - 1) {
        this.setCurrentQueryPlaceholder(this.queryContainer.currentQueryPlaceholder
          .concat(' "', fields.fieldName.toString(), '"'), false);
      } else {
        this.setCurrentQueryPlaceholder(this.queryContainer.currentQueryPlaceholder
          .concat(' "', fields.fieldName.toString(), '", '), false);
      }
    });
  }

  public async deleteSearchCategory(search: SearchCategory): Promise<void> {
    // Confirm Dialog
    const modalRef = this.modalService.open(
      ConfirmDialogComponent, {
        panelClass: 'confirm-dialog',
        data: {
          modalContent: this.translate.instant('TRANS__MODAL__DIALOG__ARE_YOU_SURE_YOU_WANT_TO_DELETE', {'name': search.category.name}),
          modalTitle: 'TRANS__MODAL__DIALOG__CONFIRMATION'
        } as ConfirmDialogData
      }
    );
    modalRef.afterClosed().pipe(
      take(1),
    ).subscribe((result) => {
      if (result) {
        if (!search || !search.categoryId) {
          // Search not provided or not yet saved => Nothing to delete => Resolved.
          return Promise.resolve();
        }
        if (search === this.searchContainer.searchCategory) {
          this.useSearchCategory(null);
        }
        this.cmsApi.deleteSearchCategory(search.categoryId);
        this.loadSearchCategories();
        // Select first item of menu on delete to refresh query;
        this.selectQueryMenu(this.pathViewMenus.menus[0]);
      }
    });
  }

  get query(): string {
    return this.searchContainer?.queryContainer.query ?? '';
  }

  set query(value: string) {
    this.queryContainer.query = value;
  }

  private async loadSearchCategories(data?): Promise<void> {
    if (!this.menuPathPlaceholder) {
      this.storedSearchCategoriesForUser = await this.cmsApi.getStoredSearchCategoriesForUser();

      if (data?.category?.name) {
        this.useSearchCategory(data, true);
      }
    }
  }
}
