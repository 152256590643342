<ul class="list-thumbnail search-result-view-list-thumbnail h-full">
  <cdk-virtual-scroll-viewport itemSize="autosize" class="scroll-viewport-height"
                               [ngClass]="{'folder': searchContainer.currentPathView.search_view.search_view_type === 'folder',
                                 'open': searchContainer.isFolderInfoOpen}">
    <ng-container *cdkVirtualFor="let art of ds; index as $index">
      <ng-container *ngIf="!art">
        <li class="search-result-view-list-thumbnail__loading-search-object"></li>
      </ng-container>
      <ng-container *ngIf="art">
        <li class="list-thumbnail__frame-and-shadow">
          <div class="list-thumbnail__item" [ngClass]="{'border-blue': searchContainer.selections.selected[art.artifact_id]}">
            <div class="list-thumbnail__row search-result-view-list-thumbnail__row">

              <div class="list-thumbnail__first-container"
                   [ngClass]="{'folder': art.object_type === 'folder'}">
                <div class="checkbox-underlay" [ngClass]="{'disabled': art.$$used}">
                  <input type="checkbox"
                    class="white-bg-in-checkbox"
                    (click)="select(art, $event)"
                    [checked]="searchContainer.selections.selected[art.artifact_id]"
                    [disabled]="art.$$used"/>
                </div>

                <div *ngIf="art.has_authority" class="authority-ribbon-container">
                  <div class="authority-ribbon"
                       [ngClass]="{
                        'situla': art.authority_id === clientConfig.AUTHORITY_ARCHAEOLOGY_ID,
                        'ribbon-culture-hub': art.authority_id === clientConfig.AUTHORITY_KULTURNAV_ID}">
                    <span>{{art.authority_id_value}}</span></div>
                </div>

                <a [routerLink]="getRouterLink(art)"
                   [queryParams]="art.$$refData?.queryParams"
                   queryParamsHandling="merge"
                   (click)="setSearchItemIndex(art.$$refData.param['searchItemIndex'])"
                   class="thumbnail-url">
                  <img *ngIf="art.$$imageUrl else elseNotThumbnail" alt="{{art.artifact_name}}"
                       [src]="art.$$imageUrl" class="thumbnail-fixed-height">
                  <ng-template #elseNotThumbnail>
                    <i class="{{art.$$noImageIcon}} imgIcon"
                       [ngClass]="{'text-blue': art.object_type === 'folder'}"></i>
                    <span *ngIf="art['$$folderItemCount']"
                          class="text-blue search-result-view-list-thumbnail__folder-items">{{art['$$folderItemCount']}}</span>
                  </ng-template>
                </a>
              </div>

              <div class="list-thumbnail__second-container" [ngClass]="{'no-image': !art.$$imageUrl}">
                  <a [routerLink]="getRouterLink(art)"
                     [queryParams]="art.$$refData?.queryParams"
                     queryParamsHandling="merge"
                     (click)="setSearchItemIndex(art.$$refData.param['searchItemIndex'])"
                     class="list-overview-fields__headline">
                    <h2 class="object-url-content">
                      <i class="list-item-icon object-icon {{art.$$icon}}"
                         [class.text-blue]="art.meta_type === MetaTypes.ADM_EVENT"></i>
                      <span class="bold list-overview-fields__headline-text search-result-overview-fields__headline-text text-link" [innerHTML]="art.artifact_name"></span>
                    </h2>
                  </a>

                  <div id="fieldContent"
                       class="row list-overview-fields__content search-result-view-list-thumbnail__overview-content">
                    <div *ngFor="let overviewFields of getColumns(art)"
                         [ngClass]="{'small-12 medium-12 columns': art?.$$columns?.length === 1,
                       'small-6 columns': art?.$$columns?.length === 2,
                       'small-4 columns': art?.$$columns?.length === 3}">

                      <div *ngFor="let field of overviewFields" class="{{field.container_class}}">

                        <div class="list-overview-fields__field search-result-view-list-thumbnail__overview-field">
                          <div class="list-overview-fields__field-title">
                            <span>{{field.title}}:</span></div>

                          <div class="list-overview-fields__field-content search-col-overview-fields__field-content">
                            <app-overview-field
                              [overviewField]="field"
                              [viewName]="searchContainer.searchResultViewName"
                              [toneDownTextColor]="true"
                            ></app-overview-field></div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>

              </div>

            </div>
        </li>
      </ng-container>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</ul>
