import {Injectable} from '@angular/core';
import {SearchParameters, SearchParametersForOverview} from '../core/definitions/search-parameters';
import {CheckFilterGroup, QueryMenu, RangeGroup, SearchView, SearchViewPath} from '../core/definitions/search-objects';
import {AConst} from '../core/a-const.enum';
import {QueryParserService} from '../core/query-parser.service';
import {CommonsService} from '../core/commons.service';
import {CmsQueueService} from '../core/cms-queue.service';
import {QueryFieldMenuService} from './query-field-menu.service';
import {ResultViewService} from './result-view.service';
import {MetaField} from '../core/definitions/meta-field';
import {SuperObjectModel} from '../core/definitions/super-object-model';
import {CmsApiService} from '../core/cms-api.service';
import {SearchResultViewType} from '../core/definitions/search-result-view-type.enum';
import {SolrFilterService} from '../core/solr-filter.service';
import {SearchFilters} from '../core/definitions/search-filters';
import {UserCacheService} from '../core/user-cache.service';
import {SearchContainer} from '../core/definitions/search-container';
import {SearchCategory} from '../core/definitions/advanced-search/search-category';
import {LoggerService} from '../core/logger.service';
import {SearchFilterGroupEnablerService} from "./search-filter-group-enabler.service";
import {SearchViewSortOrderMenuService} from "./search-view-sort-order-menu.service";

@Injectable({
  providedIn: 'root'
})
export class SearchParameterService {

  constructor(private readonly queryParser: QueryParserService,
              private readonly commons: CommonsService,
              private readonly logger: LoggerService,
              private readonly cms: CmsApiService,
              private readonly cmsQueue: CmsQueueService,
              private readonly queryMenuService: QueryFieldMenuService,
              private readonly resultViewService: ResultViewService,
              private readonly solrFilter: SolrFilterService,
              private readonly userCache: UserCacheService,
              private readonly searchFilterGroupEnablerService: SearchFilterGroupEnablerService,
              private readonly sortOrderMenuService: SearchViewSortOrderMenuService) {
  }

  private nonScrollableViews = [
    SearchResultViewType.SELECTOR.toString(), SearchResultViewType.CONTENT_LIST.toString()]

  /**
   * Get search parameters to be used in call towards the CMS search_json endpoint
   * @param searchContainer a container that contains various information about the search.
   * be included in the search filter so that only objects having these ids will be returned from the search
   * @param continueScroll set to true if this is a scroll based search.
   * @param skipFacets set to true if facets should not be generated
   */
  async getSearchParams(searchContainer: SearchContainer, continueScroll?: boolean, skipFacets?: boolean): Promise<SearchParametersForOverview> {
    const pathView = searchContainer.currentPathView || new SearchViewPath();
    const res = new SearchParametersForOverview();
    const fields = searchContainer.searchResultViewName === 'list' ? await this.getSearchFieldsSetOverviewFields(
      searchContainer) : [];
    const sort = this.getSort(searchContainer, fields);
    const rows = this.getRows(searchContainer);

    await this.setSearchFilters(res, pathView.search_view, searchContainer);
    res.write_collections_only = pathView.search_view.write_collections_only;
    res.query = this.getSearchQuery(searchContainer);
    res.query_orig = searchContainer.queryContainer.query;
    if (!skipFacets && searchContainer.queryContainer?.selectedQueryMenu?.query_type !== 'advanced') {
      // Facet generation not necessary while scrolling or for advanced search
      res.facets = this.getFacets(pathView.search_view, searchContainer);
      res.facet_range_groups = this.getFacetRangeGroups(searchContainer)
    }
    if (rows !== undefined) {
      if (!res.start && !continueScroll && !this.nonScrollableViews.includes(searchContainer.searchResultViewName)) {
        res.rows = rows * 2; // this will avoid two searches in a row for virtual scrolls
      } else {
        res.rows = rows;
      }
    }


    if (searchContainer.searchRow !== undefined) {
      this.setScrollBasedParams(searchContainer, res, continueScroll);
    } else {
      res.start = (searchContainer.searchPage - 1) * rows;
    }

    res.sort = sort;

    this.setCommonParams(searchContainer, res);
    if (searchContainer.noFullSearch) {
      res.fl = ['artifact_id'];
      res.overview = false;
    }
    if (searchContainer.queryContainer.selectedQueryMenu?.query_type === 'advanced') {
      res.search_engine = 'advanced';
      res.advanced_search_params = searchContainer.advancedSearchParams;
    } else {
      res.search_engine = searchContainer.searchEngine;
    }
    res['q.op'] = searchContainer.qOp;
    return res;
  }

  // Set search parameters that must be shared with runSearch and getSearchObjectWithOverview
  setCommonParams(searchContainer: SearchContainer, params: SearchParameters) {
    params.template_group_id = searchContainer.templateGroupId;
    params.combine_horizontal = searchContainer.combineHorizontal;
    params.overview = true;
    switch (searchContainer.searchResultViewName) {
      case SearchResultViewType.LIST:
        params.overview_fields = searchContainer.currentPathView.search_view.overview_fields;
        break;
      case SearchResultViewType.THUMBNAIL:
      case SearchResultViewType.GALLERY:
        params.overview_simple = true;
        break;
      case SearchResultViewType.LIST_THUMBNAIL:
        if (searchContainer.currentPathView.search_view.always_use_overview_fields_from_search_view) {
          params.overview_fields = searchContainer.currentPathView.search_view.overview_fields;
        }
        break;
    }
  }

  async getSearchFieldsSetOverviewFields(searchContainer: SearchContainer, noCache?: boolean): Promise<MetaField[]> {
    let fields: MetaField[];
    if (searchContainer.currentPathView) {
      const curSearchView = searchContainer.currentPathView.search_view;
      fields = curSearchView.overview_fields;
      if (!searchContainer.listFieldTemplateId || searchContainer.searchResultViewName !== SearchResultViewType.LIST) {
        const meta = this.searchFiltersToMeta(curSearchView.filters);
        const objectType = meta?.object_type;
        if ((!fields || !fields.length) || (objectType &&
          (searchContainer.oldTempGroupId !== undefined &&
            searchContainer.templateGroupId !== searchContainer.oldTempGroupId))) {
          fields = await this.getOverviewFields(objectType, searchContainer.templateGroupId, noCache);
          searchContainer.oldTempGroupId = searchContainer.templateGroupId;
          this.setBackupSearchViewOverviewFields(searchContainer, fields);
        } else {
          searchContainer.oldTempGroupId = searchContainer.templateGroupId;
        }
      } else {
        noCache = noCache || searchContainer.refreshTemplateOverviewFields;
        fields = await this.getListFieldTemplateOverviewFields(searchContainer.listFieldTemplateId, noCache);
        searchContainer.refreshTemplateOverviewFields = false;
        this.setBackupSearchViewOverviewFields(searchContainer, fields);
      }
    }
    return fields || [];
  }

  checkFieldIsSortable(overviewField: MetaField) {
    return !(overviewField.is_array || overviewField.field_type === 'array');
  }

  private setBackupSearchViewOverviewFields(searchContainer: SearchContainer, fields: MetaField[]) {
    const curSearchView = searchContainer.currentPathView.search_view;
    if (!curSearchView.$$origOverviewFields) {
      if (curSearchView.overview_fields) {
        curSearchView.$$origOverviewFields = curSearchView.overview_fields;
      } else {
        curSearchView.$$origOverviewFields = [];
      }
    }
    curSearchView.overview_fields = fields;
  }

  private searchFiltersToMeta(filters: any): SuperObjectModel {
    const filterMetaAttr = [AConst.OBJECT_TYPE, AConst.META_TYPE];
    let meta: SuperObjectModel = null;
    for (const attr of filterMetaAttr) {
      let attrVal = filters[attr];
      if (attrVal) {
        if (Array.isArray(attrVal)) {
          attrVal = attrVal[0];
        }
        meta = meta || new SuperObjectModel();
        meta[attr] = attrVal;
      }
    }

    return meta;
  }

  private async getOverviewFields(objectType: string, templateGroupId: string, noCache?: boolean): Promise<MetaField[]> {
    return new Promise((resolve, reject) => {
      this.cmsQueue.runCmsFnWithQueue(
        this.cms.getModelOverviewFields,
        {
          modelName: objectType,
          type: 'overview',
          template_group_id: templateGroupId
        },
        noCache,
        (dta: any) => resolve(dta),
        (e: any) => reject(e)
      );
    });
  }

  private async getListFieldTemplateOverviewFields(listFieldTemplateId: string, noCache?: boolean): Promise<MetaField[]> {
    return new Promise((resolve, reject) => {
      this.cmsQueue.runCmsFnWithQueue(
        this.cms.getTemplateOverviewFields,
        {template_group_id: listFieldTemplateId},
        noCache,
        (dta: any) => resolve(dta),
        (e: any) => reject(e)
      );
    });
  }

  private getSort(searchContainer: SearchContainer, fields: MetaField[]): string {
    const existsAndSortable = this.checkFieldExistsAndSortable(searchContainer.getOrder(), fields);
    let res: string;
    if (!(existsAndSortable || this.checkFieldIsInSortOrderMenus(searchContainer, searchContainer.getOrder()))) {
      let orderIsDefault = false;
      if (searchContainer.currentPathView?.search_view.sort_order_menus) {
        const sortOrderMenus = this.sortOrderMenuService.getSortOrderMenus(
          searchContainer.currentPathView.search_view.sort_order_menus);
        res = sortOrderMenus[0].order;
        orderIsDefault = true;
      } else {
        res = 'updated_at desc';
      }
      searchContainer.setOrder(res, orderIsDefault);
    }
    return this.getDynamicOrder(searchContainer)
  }

  private getDynamicOrder(searchContainer: SearchContainer): string {
    let res = searchContainer.getOrder();
    if (res.includes('{random}')) {
      res = res.replace('{random}', searchContainer.orderRandomSeed);
    }
    return res;
  }

  private getRows(searchContainer: SearchContainer) {
    let rows: number;
    if (typeof searchContainer.rows === 'number') {
      rows = searchContainer.rows;
    } else {
      rows = searchContainer.rows[searchContainer.searchResultViewName];
      if (rows) {
        if (searchContainer.displayAll) {
          rows *= searchContainer.maxPage;
        }
      } else {
        if (searchContainer.searchResultViewName) {
          rows = this.resultViewService.calcSearchRowsAndColumns(searchContainer.searchResultViewName).rows;
        } else {
          rows = searchContainer.rows[searchContainer.searchResultViews.default];
        }
      }
    }
    return rows;
  }

  private async setSearchFilters(searchParameters: SearchParameters,
                                 searchView: SearchView,
                                 searchContainer: SearchContainer) {
    if (searchView.filters) {
      const filters = await this.getProcessedSearchFilters(searchContainer, searchView.filters);
      this.solrFilter.setFqFromObject(searchParameters, filters);
    }
    this.setRestrictionFilters(searchParameters, searchContainer);
    this.setCheckedFilters(searchParameters, searchContainer);
    this.setQueryFieldFilter(searchParameters, searchContainer);
    if (searchContainer.selections.includeSelectedInFilters && searchContainer.selections.selectedItems.length > 0) {
      const selectFilter = searchContainer.selections.allSelected ? '-artifact_id' : 'artifact_id';
      this.solrFilter.addFq(
        searchParameters,
        selectFilter,
        searchContainer.selections.selectedItems.map(item => item.artifact_id));
    }
    this.setHierarchicGroupFilter(searchParameters, searchContainer);
  }

  private getSearchQuery(searchContainer: SearchContainer) {
    let query = '*';
    if (searchContainer.searchCategory) {
      query = SearchCategory.parseQuery(searchContainer.queryContainer.query, searchContainer.searchCategory);
    } else  {
      const menu = this.queryMenuService.getCurrentQueryMenu(searchContainer);
      if (!menu || menu.query_type === 'general' || menu.query_type === 'advanced') {
        query = this.queryParser.parse(searchContainer.queryContainer.query, true);
      }
    }
    let searchViewQuery = searchContainer.currentPathView?.search_view.fq;
    if (searchViewQuery) {
      while (searchViewQuery.indexOf('{{target_object_id}}') !== -1) {
        if (searchContainer.targetObject) {
          searchViewQuery = searchViewQuery.replace('{{target_object_id}}', searchContainer.targetObject.artifact_id);
        } else {
          this.logger.warn('Unable to set target object id in query, no target object available');
        }
      }
      if (!['*', '*:*'].includes(query)) {
        query = `(${query}) AND ${searchViewQuery}`;
      } else {
        query = searchViewQuery;
      }
    }
    return query;
  }

  private async getProcessedSearchFilters(searchContainer: SearchContainer,
                                          filters: SearchFilters): Promise<SearchFilters> {
    const res = new SearchFilters(filters);
    for (const key in res) {
      if (res.hasOwnProperty(key)) {
        const value = res[key];
        if (value === 'user.artifact_id') {
          const userData = await this.userCache.getUserData();
          res[key] = userData.artifact_id;
        } else if (value === '{{target_object_id}}') {
          res[key] = searchContainer.targetObject.artifact_id;
        } else if (key === '-parent_id' && value === '[* TO *]' &&
          searchContainer.searchResultViewName === SearchResultViewType.LIST &&
          searchContainer.queryContainer.query) {
          // Remove 'parent only' search for hierarchic searches if there is a non-empty query in order to search for
          // sub items
          res[key] = undefined;
        }
      }
    }
    return res;
  }

  private setCheckedFilters(searchParameters: SearchParameters, searchContainer: SearchContainer) {
    const checkedFilters = this.getFilterRangeValues(searchContainer, searchContainer.filtersFacets.checkedFilters);
    for (const key in checkedFilters) {
      if (checkedFilters.hasOwnProperty(key)) {
        const value = checkedFilters[key];
        const name = this.getFilterName(key, searchContainer.filtersFacets.filterGroups);
        if (value.length > 0) {
          this.solrFilter.addFq(searchParameters, name, value);
        }
      }
    }

  }

  private setQueryFieldFilter(searchParameters: SearchParameters, searchContainer: SearchContainer) {
    let query: string;
    const qfm: QueryMenu = this.queryMenuService.getCurrentQueryMenu(searchContainer);
    if (qfm && !['general', 'advanced'].includes(qfm.query_type)) {
      if (searchContainer.queryContainer.query) {
        query = searchContainer.queryContainer.query;
        if (qfm.query_type === 'field_upper') {
          query = this.queryParser.parse(query, true, ' ', '_').toLocaleUpperCase();
        } else {
          query = query.replaceAll(' ', '\\ ');
        }
        this.solrFilter.addFq(searchParameters, searchContainer.queryContainer.queryField, query);
      }
    }
  }

  private getFilterRangeValues(searchContainer: SearchContainer, filters: any) {
    // Filter values containing "range" values, e.g. "[
    const res = {};
    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        const value = filters[key];
        res[key] = value;
        if (Array.isArray(value)) {
          const v = value[0];
          if (typeof v === 'string' && v.indexOf('[') === 0) {
            res[key] = value[0];
          }
        } else if (typeof value === 'string' && value.indexOf('{{') === 0) {
          res[key] = this.getMappedFilterValue(searchContainer, value);
        }
      }
    }
    return res;
  }

  private setHierarchicGroupFilter(searchParameters: SearchParameters, searchContainer: SearchContainer) {
    if (searchContainer.filtersFacets.hierarchicFilterGroup) {
      const id = searchContainer.filtersFacets.hierarchicFilterGroup.lastSelectedNode?.facet.id;
      const ids = id ? [id] : ['[* TO *]'];
      this.solrFilter.addFq(searchParameters, searchContainer.filtersFacets.hierarchicFilterGroup.list_filter_field, ids);
    }
  }

  private getMappedFilterValue(searchContainer: SearchContainer, value: string) {
    if (value.indexOf('target_object_id') !== -1) {
      if (searchContainer.targetObject) {
        return searchContainer.targetObject.artifact_id;
      } else {
        this.logger.error('Search container target object is missing!');
      }
    }
    return value;
  }

  private getFilterName(key: string, filterGroups: CheckFilterGroup[]) {
    let joinPrefix = '';
    if (filterGroups) {
      for (const group of filterGroups) {
        const filter = group.filters.find(f => f.name === key);
        if (filter) {
          joinPrefix = filter.join_prefix || '';
          break;
        }
      }
    }
    return joinPrefix + key.split(':')[0];
  }

  private setRestrictionFilters(searchParameters: SearchParameters, searchContainer: SearchContainer) {
    const res = [];
    if (searchContainer.restrictions) {
      searchContainer.restrictions.filter(restrict => restrict.on)
        .forEach(restrict => this.solrFilter.addFq(searchParameters, restrict.fieldId, restrict.value));
    }
    return res;
  }

  private getFacets(searchView: SearchView, searchContainer: SearchContainer) {
    let res = searchView.facets ? this.commons.copy(searchView.facets) : [];
    let filterGroups: CheckFilterGroup[];
    if (searchContainer.filtersFacets.filterGroups?.length) {
      filterGroups = searchContainer.filtersFacets.filterGroups;
    } else {
      filterGroups = searchView.check_filter_groups;
    }
    if (filterGroups) {
      this.searchFilterGroupEnablerService.setPreDefinedEnabledForFilterGroups(filterGroups);
      res = res.concat(filterGroups
        .filter(fGroup => fGroup.enabled)
        .flatMap(fGroup => fGroup.filters)
        .map(filter => (filter.join_prefix || '') + filter.name)
        .filter(name => !res.includes(name))
      );
    }
    if (searchView.hierarchic_filter_group) {
      res.push(searchView.hierarchic_filter_group.list_filter_field);
    }
    return res;
  }

  // Facet range groups submitted to search must be enabled/disabled according to what's been enabled/disabled in GUI
  private getFacetRangeGroups(searchContainer: SearchContainer): RangeGroup[] {
    const submitGroups = searchContainer.currentPathView?.search_view.facet_range_groups;
    if (submitGroups && searchContainer.filtersFacets.facetRangeGroups) {
      for (const guiGroup of searchContainer.filtersFacets.facetRangeGroups) {
        const submitGroup = submitGroups.find(group => group.title === guiGroup.title);
        if (submitGroup) {
          submitGroup.enabled = guiGroup.enabled;
        }
      }
    }
    return submitGroups;
  }

  private setScrollBasedParams(searchContainer: SearchContainer, searchParameters: SearchParameters, continueScroll?: boolean) {
    const defaultRows = this.resultViewService.getDefaultCalculatedRowsColumns(searchContainer.searchResultViewName);
    const tileData = this.resultViewService.getViewTileData()[searchContainer.searchResultViewName];
    const addRows = tileData.scrollAddRows;
    const start = searchContainer.searchRow * defaultRows.columns;
    if (!continueScroll) {
      searchParameters.start = 0;
      searchParameters.rows = start + defaultRows.columns * addRows;
    } else {
      searchParameters.start = start;
      searchParameters.rows = defaultRows.columns * addRows;
    }
  }

  // Check whether named fields are part of overview fields and can be used for sorting
  private checkFieldExistsAndSortable(fieldName: string, fields: MetaField[]) {
    if (!fieldName) {
      return false;
    }
    if (fieldName === AConst.ARTIFACT_NAME) {
      return true;
    }
    return (fields || []).some(field =>
      fieldName.indexOf(field.name) !== -1 && this.checkFieldIsSortable(field)
    );
  }

  private checkFieldIsInSortOrderMenus(searchContainer: SearchContainer, orderField: string) {
    if (!searchContainer?.currentPathView) {
      return false;
    }
    let sortOrderMenus = [];
    const sortOrderMenuId = searchContainer.currentPathView?.search_view?.sort_order_menus;
    if (sortOrderMenuId) {
      sortOrderMenus = this.sortOrderMenuService.getSortOrderMenus(sortOrderMenuId);
    }

    return (sortOrderMenus || []).some(sortOrderMenu => orderField === sortOrderMenu.order);
  }

}
