import {Component, Input, OnInit} from '@angular/core';
import {SearchHandlerService} from '../../search-handler.service';
import {AConst} from '../../../core/a-const.enum';
import {SearchContainer} from '../../../core/definitions/search-container';
import {SearchViewSortOrderMenuService} from "../../search-view-sort-order-menu.service";
import {SortOrderMenu} from "../../../core/definitions/search-objects";

@Component({
  selector: 'app-search-menu-sort',
  templateUrl: './search-menu-sort.component.html',
  styleUrls: ['./search-menu-sort.component.scss']
})
export class SearchMenuSortComponent implements OnInit {

  @Input() searchContainer: SearchContainer;
  @Input() viewName: String;

  AConst = AConst;
  constructor(private searchHandler: SearchHandlerService,
              private sortOrderMenuService: SearchViewSortOrderMenuService) {
  }

  ngOnInit() {
    if (!this.viewName) {
      this.viewName = 'search';
    }
  }

  get sortOrderMenus(): SortOrderMenu[] {
    return this.sortOrderMenuService.getSortOrderMenus(
      this.searchContainer.currentPathView.search_view.sort_order_menus)

  }



  changeSortOrder(s) {
    this.searchHandler.setOrder(this.searchContainer, s[AConst.ORDER]);
  }

}
