import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {CommonsService} from '../../core/commons.service';
import {CheckFilterGroup, SearchViewMenu} from '../../core/definitions/search-objects';
import {SearchFilterService} from '../search-filter.service';
import {SearchExecutorService} from '../search-executor.service';
import { SearchResultViewType } from '../../core/definitions/search-result-view-type.enum';
import {SearchContainer} from '../../core/definitions/search-container';
import {SearchFacetService} from '../search-facet.service';
import {FocusServiceImplementation, SearchFocusService} from "../search-focus.service";
import {SearchHandlerService} from "../search-handler.service";
import {TranslateService} from "@ngx-translate/core";
import {SearchViewCategoryMenuService} from "../search-view-category-menu.service";

export class PathView {
  menus: Array<SearchViewMenu> = [];
}

@Component({
  selector: 'app-search-filter-menu',
  templateUrl: './search-filter-menu.component.html',
  styleUrls: ['./search-filter-menu.component.scss']
})
export class SearchFilterMenuComponent implements OnInit, OnDestroy {

  @Input() searchContainer: SearchContainer;
  @Input() sideMenu: boolean;
  @Input() dropdownMenu: boolean;
  @Input() sideMenuSmallScreen: boolean;
  @Output() toggleSideMenuSmallScreen = new EventEmitter<object>();
  @ViewChild('searchFilterMenu', {static: false}) searchFilterMenu: ElementRef;

  pathView = new PathView();
  SearchResultViewNames = SearchResultViewType;

  private path = 'not set';
  private curFocusId: string;
  private fsi: FocusServiceImplementation;

  constructor(private searchExecutorService: SearchExecutorService,
              private searchFilterService: SearchFilterService,
              private commons: CommonsService,
              private searchFacetService: SearchFacetService,
              private searchFocusService: SearchFocusService,
              private searchHandler: SearchHandlerService,
              private translate: TranslateService,
              private categoryMenuService: SearchViewCategoryMenuService) {
  }

  ngOnInit() {
    this.searchExecutorService.subscribeToSearchResult(this.searchContainer, this.init);
    this.fsi = this.searchFocusService.createFSI(this.searchContainer);
  }

  ngOnDestroy(): void {
    this.searchExecutorService.unSubscribeToSearchResult(this.searchContainer, this.init);
  }

  async clearSetFilters() {
    if (this.searchContainer.focus.curFocusId) {
      await this.searchHandler.goPathView(this.fsi.getCurrentFocus().focus.homePath, this.searchContainer);
    }
    else {
      await this.searchHandler.goPathView('home', this.searchContainer);
    }
  }

  toggleSideFilterMenuSmallScreen() {
    this.toggleSideMenuSmallScreen.emit();
  }

  scrollToBottom() {
    setTimeout(() => {
      if (this.searchFilterMenu) {
        this.searchFilterMenu.nativeElement.scrollTop = this.searchFilterMenu.nativeElement.scrollHeight;
      }
    }, 1200);
  }

  showClearButton(): boolean {
    return (!this.searchContainer.focus.curFocusId && Object.keys(this.searchFilterService.getCheckedFilters(this.searchContainer)).length > 0) ||
      this.searchContainer.focus.curFocusId && this.fsi.focusHasChanges(this.searchContainer.focus.curFocus) ||
      this.path !== 'home';


  }

  private async setCheckFilters() {
    if (!this.searchContainer.filtersFacets.filterGroups) {
      await this.searchFilterService.setCheckFilterGroups(this.searchContainer);
    }
    this.searchContainer.filtersFacets.filterGroups = this.searchContainer.filtersFacets.filterGroups
      ? this.searchContainer.filtersFacets.filterGroups.sort((a, b) => this.translate.instant(a.title).localeCompare(this.translate.instant(b.title)))
      : undefined;
  }

  private init = () => {
    const sc = this.searchContainer;
    if (sc) {
      if (this.path !== sc.path || this.curFocusId !== sc.focus.curFocusId) {
        let menus = this.categoryMenuService.getCategoryMenus(this.searchContainer.currentPathView.search_view.menus);
        if (menus) {
          menus = this.commons.sortArray(menus, 'order');
          for (const mainMenu of menus) {
            mainMenu.menus = mainMenu.menus ? this.commons.sortArray(mainMenu.menus, 'order') : [];
          }
          this.pathView.menus = menus;
        }
        this.path = sc.path;
        this.curFocusId = sc.focus.curFocusId;
      }

      if (sc.searchResult) {
        this.setCheckFilters().then(() => {
          for (const group of this.searchContainer.filtersFacets.filterGroups || []) {
            this.sortGroupChildren(group);
          }
          this.setFilterCount();
        });
        this.setMenuCount();
      }
    }
  }

  private setMenuCount() {
    if (this.searchContainer.currentPathView.path_name.indexOf(this.searchContainer.path) !== 0) {
      console.warn('Incompatible paths');
      return;
    }
    const categoryMenus = this.categoryMenuService.getCategoryMenus(this.searchContainer.currentPathView.search_view.menus);
    for (const mainMenu of  categoryMenus) {
      if (this.searchContainer.path.indexOf(this.searchContainer.path) === 0) {
        mainMenu.count = this.searchFacetService.getMenuCount(mainMenu, this.searchContainer);
        if (mainMenu.menus) {
          for (const subMenu of mainMenu.menus) {
            subMenu.count = this.searchFacetService.getMenuCount(subMenu, this.searchContainer);
          }
        }
      }
    }
  }

  private setFilterCount() {
    for (const filterGroup of this.searchContainer.filtersFacets.filterGroups || []) {
      for (const filter of filterGroup.checkFilters || []) {
        filter.count = this.searchFilterService.getFilterCount(filter, this.searchContainer);
      }
    }
  }

  private sortGroupChildren(group: CheckFilterGroup) {
    group.filters = group.filters.sort((a, b) => this.translate.instant(a.title).localeCompare(this.translate.instant(b.title)));
  }
}
