<div class="search-result-view-thumbnail h-full">
  <ul class="thumbContainer h-full">
    <cdk-virtual-scroll-viewport itemSize="256"
                                 class="scroll-viewport-height"
                                 [style.width]="width"
                                 [ngClass]="{'folder': searchContainer.currentPathView.search_view.search_view_type === 'folder',
                                 'open': searchContainer.isFolderInfoOpen}">
      <ng-container *cdkVirtualFor="let searchObjectRow of ds" class="scroll-item">
        <ng-container *ngIf="searchObjectRow">

          <li *ngFor="let art of searchObjectRow.columns" class="searchResultItem searchResultPanel"
              [ngClass]="{'selected': searchContainer.selections.selected[art.artifact_id]}">
            <div *ngIf="art">
              <div class="searchResultPanel--img-container"
                   [ngClass]="{'folder': art.object_type === 'folder'}">

                <div class="checkbox-underlay">
                  <label class="custom-checkbox-container">
                    <input type="checkbox"
                           (click)="select(art, $event)"
                           [checked]="searchContainer.selections.selected[art.artifact_id]">
                    <span class="ripple"></span>
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div *ngIf="art.has_authority" class="authority-ribbon-container">
                  <div class="authority-ribbon"
                       [ngClass]="{'situla': art.authority_id === clientConfig.AUTHORITY_ARCHAEOLOGY_ID,'ribbon-culture-hub': art.authority_id === clientConfig.AUTHORITY_KULTURNAV_ID}">
                    {{art.authority_id_value}}</div></div>

                <a [routerLink]="getRouterLink(art)"
                   [queryParams]="art.$$refData?.queryParams"
                   queryParamsHandling="merge"
                   (click)="setSearchItemIndex(art.$$refData.param['searchItemIndex'])"
                   class="search-result-view-thumbnail__thumbnail-url">
                  <img *ngIf="art.$$imageUrl else elseNotThumbnail" alt="{{art.artifact_name}}"
                       [src]="art.$$imageUrl">
                  <ng-template #elseNotThumbnail>
                    <i class="{{art.$$noImageIcon}} imgIcon"
                       [ngClass]="{'text-blue': art.object_type === 'folder'}"></i></ng-template></a>
              </div>

              <div class="searchResultPanel--thumbInfo-container">
                <div class="search-result-view-thumbnail__headline bold">
                  <i class="searchIcon object-icon {{art.$$icon}}"
                     [class.text-blue]="art.meta_type === MetaTypes.ADM_EVENT"></i>
                  <a [routerLink]="getRouterLink(art)"
                     [queryParams]="art.$$refData?.queryParams"
                     queryParamsHandling="merge"
                     (click)="setSearchItemIndex(art.$$refData.param['searchItemIndex'])"
                     class="search-result-view-thumbnail__headline-url">
                    <span class="object-url-content bold" [innerHTML]="art.$$name"></span>
                  </a>
                </div>

                <app-search-tile-overview-fields
                  [searchObject]="art"
                  [viewName]="searchContainer.searchResultViewName">
                </app-search-tile-overview-fields>
              </div>
            </div>
          </li>
          <li *ngIf="columns > searchObjectRow.columns.length"
              [ngStyle]="{'width': (202 * (columns - searchObjectRow.columns.length)) + 'px'}"
              class="search-result-view-thumbnail__empty-column"></li>
        </ng-container>
      </ng-container>
    </cdk-virtual-scroll-viewport>

  </ul>
</div>
