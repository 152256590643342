<app-m3-global-kpl-styles>
@if (object) {
  <div class="flex flex-col gap-4">
    @switch ((changelogType$ | async);) {
      @case ('folders') {
        <section>
          <h2>{{ 'TRANS__OBJECT_INFORMATION_TABS__FOLDERS' | translate }}</h2>
          <div>
            <app-object-folders [object]="object"></app-object-folders>
          </div>
        </section>
      }
      @case ('changes') {
        <section>
          <h2>{{ 'TRANS__OBJECT_INFORMATION_TABS__CHANGES' | translate }}</h2>
          <div>
            <app-object-update-log [contextId]="object.artifact_id"
                                   [order]="'desc'"
                                   [getLogItems]="true"></app-object-update-log>
          </div>
        </section>
      }
      @case ('id-numbers') {
        <section>
          <h2>{{ 'TRANS__OBJECT_INFORMATION_TABS__IDENTIFICATION_NUMBER_LOG' | translate }}</h2>
          <div>
            <app-identification-number-log [object]="object"></app-identification-number-log>
          </div>
        </section>
      }
      @case ('collection-log') {
        <section>
          <h2>{{ 'TRANS__OBJECT_INFORMATION_TABS__COLLECTION_LOG' | translate }}</h2>
          <div>
            <app-collection-log [object]="object"></app-collection-log>
          </div>
        </section>
      }
      @case ('collection-status-log') {
        <section>
          <h2>{{ 'TRANS__OBJECT_INFORMATION_TABS__COLLECTION_STATUS_LOG' | translate }}</h2>
          <div>
            <app-collection-status-log [object]="object"></app-collection-status-log>
          </div>
        </section>
      }
      @case ('quantity-log') {
        <section>
          <h2>{{ 'TRANS__OBJECT_INFORMATION_TABS__QUANTITY_LOG' | translate }}</h2>
          <app-auto-expandable-overflow-container [maxHeight]="240">
            <div>
              <app-quantity-log [object]="object"></app-quantity-log>
            </div>
          </app-auto-expandable-overflow-container>
        </section>
      }
      @default {
        <h2>DEFAULT!</h2>
      }
    }
  </div>
}
</app-m3-global-kpl-styles>
