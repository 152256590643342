@if (item.type === 'group') {
  <button mat-button [matMenuTriggerFor]="level1Menu">
    @if (item.icon) {
      <mat-icon matMenuItemIcon class="material-symbols-outlined">{{ item.icon }}</mat-icon>
    }
    {{ item.label | translate }}
  </button>
  <mat-menu #level1Menu="matMenu">
    <app-m3-wrapper>
      @for (child of item.children; track child.id) {
        @if (child.type === 'group') {
          <mat-menu #childMenu="matMenu">
            <app-m3-wrapper>
              <div class="flex flex-col">
                @for (grandChild of child.children; track grandChild.id) {
                  <button mat-menu-item (click)="itemClick.emit(grandChild)">
                    @if (grandChild.icon) {
                      <mat-icon matMenuItemIcon class="material-symbols-outlined">{{ grandChild.icon }}</mat-icon>
                    }
                    {{ grandChild.label | translate }}
                  </button>
                }
              </div>
            </app-m3-wrapper>
          </mat-menu>
          <button mat-menu-item [matMenuTriggerFor]="childMenu">
            @if (child.icon) {
              <mat-icon matMenuItemIcon class="material-symbols-outlined">{{ child.icon }}</mat-icon>
            }
            {{ child.label | translate }}
          </button>
        } @else {
          <button mat-menu-item (click)="itemClick.emit(child)">
            @if (child.icon) {
              <mat-icon matMenuItemIcon class="material-symbols-outlined">{{ child.icon }}</mat-icon>
            }
            {{ child.label | translate }}
          </button>
        }
      }
    </app-m3-wrapper>
  </mat-menu>
} @else {
  <button mat-button (click)="itemClick.emit(item)">
    @if (item.icon) {
      <mat-icon class="material-symbols-outlined">{{ item.icon }}</mat-icon>
    }
    {{ item.label | translate }}
  </button>
}
